@tailwind base;
@tailwind components;
@import url("../../../packages/ui/styles/globals.css");

html {
  background-color: #0e0e0e;
}
body {
  overflow-x: hidden;
  min-height: -webkit-fill-available;
  @apply font-body;
}

.dark {
  color-scheme: dark;
}

.flex-center {
  @apply flex items-center justify-center;
}

.center {
  @apply flex items-center justify-center;
}

.faq-details p.nested {
  margin-top: 0 !important;
}

.faq-details a {
  @apply font-medium underline text-neutral-800;
}

.faq-details b {
  @apply font-heavy;
}
.notice a {
  @apply block md:inline;
}

.text-gradient {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.cta {
  @apply uppercase font-cta;
}

button:disabled,
input:disabled {
  cursor: not-allowed !important;
  @apply shadow-none;
}

.slanted {
  transform: skew(-12deg);
  -webkit-transform: skew(-12deg);
  -moz-transform: skew(-12deg);
  -o-transform: skew(-12deg);
}

.hide-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.heading {
  @apply font-extrabold tracking-wide uppercase font-heavy;
}
.heading.alone {
  @apply text-h1 lg:text-lg-h1;
}
.heading.upper {
  @apply text-super lg:text-lg-super;
}
.heading.below {
  @apply text-duper lg:text-lg-duper;
}
.body {
  @apply text-sub lg:text-lg-sub;
}

.review-details b {
  @apply font-bold text-foreground text-opacity-100;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbars-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbars-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* carousel start */

.carousel {
  display: flex;
  flex-direction: row;

  padding: 16px 0;
  margin: 16px (16px * -1);

  overflow-x: auto;
}

.horse {
  flex: 0 0 100%;

  display: flex;
  padding: 0 (16px / 2);
  box-sizing: border-box;

  list-style-type: none;
}

.card {
  flex: 1 1 0;
  margin: 0 (16px / 2);
  border-radius: 8px;
  box-shadow: 0 0 4px #333;
  background: white;
  min-height: 160px;
}

.card-spacer {
  flex: 1 1 0;
}
.carousel {
  scroll-snap-type: x mandatory;
  overscroll-behavior: contain;
}

.horse {
  scroll-snap-align: start;
}

/* carousel end */

.bg-dark-slanted-gradient {
  background: linear-gradient(122.48deg, #3e3e3e 12.96%, #1f1f1f 70.01%);
}

b,
strong {
  @apply font-heavy;
}

/* countdown svg */
svg.countdown {
  transform: rotateY(-180deg) rotateZ(-90deg);
}

body {
  --countdown-duration: 5s;
}

.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes sparkleComeInOut {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}
@keyframes sparkleSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

.sparkling-wrapper {
  position: absolute;
  display: block;
  animation: sparkleComeInOut 1.8s ease 0s 1 normal forwards running;
}
.sparkling {
  animation: sparkleSpin 2s linear;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

svg.countdown circle {
  stroke-dasharray: 113px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 4px;
  stroke: #e6ff00;
  fill: none;
  animation: countdown var(--countdown-duration) linear forwards;
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 113px;
  }
}

@keyframes fade-in-animate {
  0% {
    opacity: 0;
    filter: brightness(1) blur(10px);
  }
  10% {
    opacity: 1;
    filter: brightness(1.25) blur(5px);
  }
  100% {
    opacity: 1;
    filter: brightness(1) blur(0);
  }
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 6px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

@keyframes floating-shadow {
  0% {
    scale: 1;
  }
  50% {
    scale: 0.7;
  }
  100% {
    scale: 1;
  }
}

@keyframes shake {
  30%,
  50%,
  70% {
    transform: translate3d(0, -4px, 0);
    opacity: 0.9;
  }

  40%,
  60% {
    transform: translate3d(0, 4px, 0);
    opacity: 1;
  }
}

.shake {
  animation: shake 0.95s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.floating-shadow {
  animation-name: floating-shadow;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.floating {
  animation-name: floating;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.fade-in {
  animation: fade-in-animate 0.8s linear forwards;
}

.markdown ol {
  counter-reset: orderedlist;
}
.chatbot-markdown ol {
  counter-reset: orderedlist;
}
.chatbot-markdown li p {
  margin: 0;
}
.chatbot-markdown td {
  text-align: center;
}
.markdown a,
.chat a {
  @apply text-foreground underline;
}
.review-details .markdown em {
  @apply text-primary;
  font-style: normal !important;
}
.markdown ol li {
  @apply flex items-center justify-start text-lg text-foreground/80;
}
.markdown ol li::before {
  counter-increment: orderedlist;
  content: counter(orderedlist);
  @apply flex items-center justify-center w-12 h-12 p-6 mr-5 text-2xl font-bold border-2 rounded-full font-display text-foreground/75 border-primary;
}

.chatbot-markdown ol li {
  @apply flex items-start justify-start;
}

.chatbot-markdown ol > li::before {
  counter-increment: orderedlist;
  content: counter(orderedlist);
  @apply flex items-center justify-center w-6 h-6 p-2 mr-2 text-sm border rounded-full text-opacity-75 border-[#6af478];
}

.iphone {
  position: relative;
  /* margin: 40px auto;
  width: 400px;
  height: 900px; */
  border-radius: 40px;
}
.notch {
  top: 0;
  border-radius: 0 0 28px 28px;
}
.notch::before,
.notch::after {
  content: "";
  position: absolute;
  top: 0;
  left: -7px;
  width: 14px;
  height: 7px;
  background-size: 50% 100%;
  background-repeat: no-repeat;
  background-image: radial-gradient(
    circle at 0 100%,
    transparent 6px,
    #222 7px
  );
}
.notch::after {
  left: 100%;
  margin-right: corner-sizepx;
  background-image: radial-gradient(
    circle at 100% 100%,
    transparent 6px,
    #222 7px
  );
}

.bottom-apple-padding {
  bottom: 0;
}

@supports (padding: max(0px)) {
  .apple-padding {
    padding-top: env(safe-area-inset-top);
  }
  .bottom-apple-padding {
    padding-bottom: 4px;
    padding-bottom: env(safe-area-inset-bottom);
  }
  .bottom-apple-margin {
    margin-bottom: 4px;
    margin-bottom: env(safe-area-inset-bottom);
  }
}

/* disable double tap to zoom */
button {
  touch-action: manipulation;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.markdown.accordion-item a {
  @apply text-black underline;
}

.shimmer {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
  height: 100%;
  background: radial-gradient(white, #3984ff00 80%);
  opacity: 0;
  transition: opacity 0.2s;
}

.scroll-down-indicator {
  border-radius: 20px;
  height: 50px;
  width: 30px;
}
.scroll-down-indicator::before {
  animation: scrollDownAnimation 2s infinite;
  background-color: #fff;
  border-radius: 100%;
  content: "";
  height: 6px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 10px;
  width: 6px;
}
@keyframes disco {
  0% {
    content: "";
    transform: translateY(-50%) rotate(0deg);
  }
  to {
    content: "";
    transform: translateY(-50%) rotate(1turn);
  }
}

.before\:animate-disco:before {
  content: var(--tw-content);
  animation: disco 2s linear infinite;
}

.group[data-state="active"]
  .group-data-\[state\=\'active\'\]\:before\:to-transparent:before {
  content: var(--tw-content);
  --tw-gradient-to: transparent;
}

.group[data-state="active"]
  .group-data-\[state\=\'active\'\]\:before\:from-mint-11:before {
  content: var(--tw-content);
  --tw-gradient-from: rgba(230, 255, 0.8);
  --tw-gradient-to: rgba(44, 255, 209, 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.group[data-state="active"]
  .group-data-\[state\=\'active\'\]\:before\:via-transparent:before {
  content: var(--tw-content);
  --tw-gradient-to: transparent;
  --tw-gradient-stops: var(--tw-gradient-from), transparent,
    var(--tw-gradient-to);
}

.group[data-state="active"]
  .group-data-\[state\=\'active\'\]\:before\:bg-gradient-conic:before {
  content: var(--tw-content);
  background-image: conic-gradient(var(--tw-gradient-stops));
}

.group[data-state="active"]
  .group-data-\[state\=\'active\'\]\:before\:animate-disco-border:before {
  content: var(--tw-content);
  animation: disco 6s linear infinite;
}

[data-radix-popper-content-wrapper] {
  @apply z-50 !important;
}

.animate-marquee {
  animation: marquee var(--duration) linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-50% - var(--gap) / 2));
  }
}

@-moz-keyframes scrollDownAnimation {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes scrollDownAnimation {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes scrollDownAnimation {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 0;
  }
}
@keyframes scrollDownAnimation {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 0;
  }
}

@tailwind utilities;

/* overwrite tailwind */
/* handles height in mobile browsers */
.h-screen {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings:
      "rlig" 1,
      "calt" 1;
  }
}
